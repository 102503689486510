const contact = (XHRWrap) => {
    const contactForm = document.querySelector("#contact-form");
    if (contactForm) {
        let contactMessage;
        let formSending = false;
        const formMessages = contactForm.querySelectorAll(".checkout__form-message");
        const checkMessage = contactForm.querySelector(".checkout__form-message.check");
        contactForm.addEventListener("submit", function(e){
            e.preventDefault();
            e.stopPropagation();
            if (formSending) {
                return;
            }
            for (var i = 0; i < formMessages.length; i++) {
                formMessages[i].classList.remove("active");
            }
            checkMessage.classList.add("active");
            formSending = true;
            contactMessage = new XHRWrap({
                url: "/contact",
                form: contactForm,
                additionalFormData: {
                    id: "kiosk",
                }
            });
            contactMessage.send().then(
                result => {
                    console.log(result);
                    for (var i = 0; i < formMessages.length; i++) {
                        formMessages[i].classList.remove("active");
                        if (formMessages[i].classList.contains("succes") && result.result) {
                            formMessages[i].querySelector("p").innerText = result.message;
                            formMessages[i].classList.add("active");
                            contactForm.classList.add("disabled");
                            formSending = false;
                        }
                        if (formMessages[i].classList.contains("error") && !result.result) {
                            formMessages[i].querySelector("p").innerText = result.message;
                            formMessages[i].classList.add("active");
                            formSending = false;
                        }
                    }
                    fbq('track', 'Contact');
                },
                error => {
                    console.error(error);
                    for (var i = 0; i < formMessages.length; i++) {
                        formMessages[i].classList.remove("active");
                        if (formMessages[i].classList.contains("error")) {
                            formMessages[i].querySelector("p").innerHTML = "<span class='title'>UPS! COŚ POSZŁO NIE TAK</span><span class='subtitle'>Prosimy spróbować ponownie za chwilę</span>";
                            formMessages[i].classList.add("active");
                            formSending = false;
                        }
                    }
                }
            )
        });
    }
}

export default contact;

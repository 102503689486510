const order = (XHRWrap, docCookies) => {
    // Order
    const basketWidget = document.querySelector(".basket-widget");
    const checkoutForm = document.querySelector(".checkout__form");
    const checkoutLeft = document.querySelector(".checkout__col-left");
    const checkoutBanks = document.querySelector(".checkout__banks");
    const checkoutTop = document.querySelector(".checkout__order-top");
    const checkChanges = document.querySelector(".check-changes");
    const checkChangesInput = document.querySelector(".check-changes__input");
    const basketCount = document.querySelector(".-basket .num");
    const orderAddBtns = document.querySelectorAll("[data-order='add']");
    const checkoutBasketWrap = document.querySelectorAll(".checkout__basket-wrap");
    const checkoutBasketSidebar = document.querySelector(".checkout__basket-wrap.checkout__basket-wrap--sidebar");
    const basketAddContent = document.querySelector(".basket-add__content-middle");
    const basketAddWrap = document.querySelector(".basket-add");
    const basketAddClose = document.querySelectorAll("[data-basket-box='close']");
    const checkoutOrder = document.querySelector(".basket-widget .checkout-order");
    const basketSummaryVal = document.querySelector(".basket-widget .checkout-order__summary-value");
    const basketShippingVal = document.querySelector(".basket-widget .checkout-order__shipping-value");
    const sendOrder = document.querySelector("[data-order='send-order']");
    const widgetDiscount = document.querySelector(".basket-widget .checkout-order__summary > .checkout-order__discount");
    const basketError = document.querySelector(".basket-add--error");
    const checkBotPrice = document.querySelector(".checkout__price-total span");
    const checkSend = document.querySelector(".check-send__input[name='next']");
    const basketWidgetLoader = document.querySelector(".basket-widget__loader");
    const pixelProductAdd = false;
    let basketSidebarItems;

    let send;
    let request;
    let add;
    let checkCode;
    let newQuantity;
    let formSending = false;
    let basketSending = false;
    let checkingCode = false;
    let checkDiscount = false;
    let formState;

    if (checkChanges) {
        formState = new URLSearchParams(new FormData(checkoutForm)).toString();
    }
    function showError() {
        basketAddWrap.classList.remove("open");
        basketError.classList.add("open");
        let timer = setTimeout(function(){
            basketAddWrap.classList.remove("success");
            basketAddWrap.classList.remove("inBasket");
            basketAddWrap.classList.remove("loaded");
        }, 300);
    }
    const phoneNumbers = document.querySelectorAll('.inputs-inside input[type=number]');
    if(phoneNumbers.length > 0) {
        const phoneNumber = document.querySelector("#in-telefon").value;
        const numberInputs = document.querySelectorAll(".inputs-inside__number");
        if (phoneNumber.length === 9 && numberInputs.length === 9) {
            const numbers = phoneNumber.split("");
            for (var i = 0; i < numberInputs.length; i++) {
                numberInputs[i].value = numbers[i];
            }
        }

        const checkPhone = (mutationList, observer) => {
            const phoneNumber = document.querySelector("#in-telefon").value;
            const numberInputs = document.querySelectorAll(".inputs-inside__number");
            if (phoneNumber.length === 9 && numberInputs.length === 9) {
                const numbers = phoneNumber.split("");
                for (var i = 0; i < numberInputs.length; i++) {
                    numberInputs[i].value = numbers[i];
                }
            }
        }

        const config = { childList: true, subtree: true };
        const observer = new MutationObserver(checkPhone);
        observer.observe(checkoutLeft, config);

        checkoutForm.addEventListener('input', function(e) {
            const phoneInput = this.querySelector("#in-telefon");
            const numberInputs = this.querySelectorAll(".inputs-inside__number");
            if (e.target.classList.contains("inputs-inside__number") && e.target.value.length > 1) {
                e.target.value = e.target.value.slice(1);
            }
            if (e.target.classList.contains("inputs-inside__number")) {
                if (e.target.value.length > 0) {
                    if (e.target.nextElementSibling !== null) {
                        if (e.target.nextElementSibling.getAttribute('type') == 'number') {
                            e.target.nextElementSibling.focus();
                        }
                        else if (e.target.nextElementSibling.nextElementSibling.getAttribute('type') == 'number') {
                            e.target.nextElementSibling.nextElementSibling.focus();
                        }
                        else {
                            e.target.blur();
                        }
                    }
                    else {
                        e.target.blur();
                    }
                }
                else if (e.target.value.length === 0) {
                    if (e.target.previousElementSibling !== null) {
                        if (e.target.previousElementSibling.getAttribute('type') == 'number') {
                            e.target.previousElementSibling.focus();
                        }
                        else if (e.target.previousElementSibling.previousElementSibling !== null && e.target.previousElementSibling.previousElementSibling.getAttribute('type') == 'number') {
                            e.target.previousElementSibling.previousElementSibling.focus();
                        }
                        else {
                            e.target.blur();
                        }
                    }
                    else {
                        e.target.blur();
                    }
                }

            }
            setTimeout(function() {
                let number = "";
                for (var i = 0; i < numberInputs.length; i++) {
                    number += `${numberInputs[i].value}`;
                }
                if (number.length === 9 || number.length === 0) {
                    phoneInput.value = number;
                }
            }, 1);
        })
    }

    if (checkoutBasketWrap.length > 0) {
        for (var i = 0; i < checkoutBasketWrap.length; i++) {
            checkoutBasketWrap[i].addEventListener("click", function(e){
                if (e.target.classList.contains("select-gift__list-title")){
                    document.querySelector(".select-gift").classList.toggle("open");
                }
                if (basketSending) {
                    return;
                }
                const self = this;
                basketSidebarItems = document.querySelector(".basket-widget .checkout__basket-wrap .simplebar-content");
                if (checkChanges) {
                    let newFormState = new URLSearchParams(new FormData(checkoutForm)).toString();
                    if (formState == newFormState) {
                        checkChangesInput.value = 0;
                    }
                    else {
                        checkChangesInput.value = 1;
                    }
                }
                if ((
                    e.target.dataset.basket == "remove-all" ||
                    e.target.dataset.basket == "remove" ||
                    e.target.dataset.basket == "add") &&
                    !basketSending &&
                    !formSending )
                {
                    if (basketWidgetLoader) {
                        basketWidgetLoader.classList.add("open");
                    }
                    basketSending = true;
                    this.parentElement.classList.add("sending");
                    const etarget = e.target.dataset.basket;
                    if (e.target.dataset.basket == "remove-all") {
                        request = new XHRWrap({
                            url: "/koszyk/usun/" + e.target.dataset.id,
                            form: checkoutForm,
                        });
                    }
                    else if (e.target.dataset.basket == "remove") {
                        newQuantity = parseInt(e.target.dataset.quantity) - 1;
                        if (newQuantity <= 0) {
                            request = new XHRWrap({
                                url: "/koszyk/usun/" + e.target.dataset.id,
                                form: checkoutForm,
                            });
                        }
                        else {
                            request = new XHRWrap({
                                url: "/koszyk/ilosc/" + e.target.dataset.id + "/" + newQuantity,
                                form: checkoutForm,
                            });
                        }
                    }
                    else if (e.target.dataset.basket == "add") {
                        newQuantity = parseInt(e.target.dataset.quantity) + 1;
                        request = new XHRWrap({
                            url: "/koszyk/ilosc/" + e.target.dataset.id + "/" + newQuantity,
                            form: checkoutForm,
                        });
                    }
                    request.send().then(
                        result => {
                            if (result.html.hasOwnProperty("basket")) {
                                if (result.result) {
                                    for (var i = 0; i < checkoutBasketWrap.length; i++) {
                                        if (checkoutBasketWrap[i].classList.contains("checkout__basket-wrap--sidebar")) {
                                            continue;
                                        }
                                        checkoutBasketWrap[i].innerHTML = result.html.basket;
                                    }
                                }
                                else if (!result.result && result.html.hasOwnProperty("recommendation")) {
                                    for (var i = 0; i < checkoutBasketWrap.length; i++) {
                                        if (checkoutBasketWrap[i].classList.contains("checkout__basket-wrap--sidebar")) {
                                            continue;
                                        }
                                        checkoutBasketWrap[i].innerHTML = result.html.recommendation;
                                    }
                                    if (checkoutBasketSidebar) {
                                        let checkoutBasketOrder = checkoutBasketSidebar.querySelector(".checkout-order");
                                        checkoutBasketOrder.classList.add("checkout-order--empty");
                                    }
                                    if (basketWidget) {
                                        basketWidget.classList.add("basket-widget--empty");
                                    }
                                }
                            }
                            if (result.html.hasOwnProperty("basketList") && basketSidebarItems) {
                                basketSidebarItems.innerHTML = result.html.basketList;
                            }
                            if (result.html.hasOwnProperty("orderTop") && checkoutTop) {
                                checkoutTop.innerHTML = result.html.orderTop;
                            }
                            if (result.html.hasOwnProperty("order") && checkoutLeft) {
                                checkoutLeft.innerHTML = result.html.order;
                            }
                            if (result.data.hasOwnProperty("quantity") && basketCount) {
                                basketCount.innerText = result.data.quantity;
                            }
                            if (result.html.hasOwnProperty("amountTotal") && basketSummaryVal) {
                                basketSummaryVal.innerText = result.html.amountTotal;
                            }
                            if (result.html.hasOwnProperty("shippingCost") && basketShippingVal) {
                                basketShippingVal.innerText = result.html.shippingCost;
                            }
                            if (result.html.hasOwnProperty("discount") && widgetDiscount) {
                                widgetDiscount.innerHTML = result.html.discount;
                            }
                            // INPOST
                            const inpostRadio = document.querySelector("#sposob_dostawy_761");
                            const inpostWrap = document.querySelector(".checkout__inpost");
                            if (inpostWrap && inpostRadio) {
                                if (inpostRadio.checked) {
                                    inpostWrap.classList.add("open");
                                }
                                else {
                                    inpostWrap.classList.remove("open");
                                }
                            }
                            // INPOST END
                            self.parentElement.classList.remove("sending");
                            if (basketWidgetLoader) {
                                basketWidgetLoader.classList.remove("open");
                            }
                            basketSending = false;
                            if (e.target.dataset.basket == "add") {
                                dataLayer.push({ ecommerce: null });
                                dataLayer.push({
                                   'event':'addToCart',
                                   'ecommerce': {
                                       'add': {
                                           'products': [{
                                                'id': e.target.closest("[data-layer='true']").dataset.id,
                                                'name': e.target.closest("[data-layer='true']").dataset.name,
                                                'price': e.target.closest("[data-layer='true']").dataset.price,
                                                'brand': e.target.closest("[data-layer='true']").dataset.brand,
                                                'category': e.target.closest("[data-layer='true']").dataset.category,
                                                'variant': e.target.closest("[data-layer='true']").dataset.variant,
                                                'quantity': 1,
                                                'dimension7': e.target.closest(".checkout-order__item").dataset.dimension,
                                                'dimension8': e.target.dataset.hasOwnProperty("paperType") ? e.target.dataset.paperType : "",
                                            }]
                                        }
                                    }
                                });
                            }
                            if (e.target.dataset.basket == "remove") {
                                dataLayer.push({ ecommerce: null });
                                dataLayer.push({
                                    'event':'removeFromCart',
                                    'ecommerce': {
                                        'remove': {
                                            'products': [{
                                                'id': e.target.closest("[data-layer='true']").dataset.id,
                                                'name': e.target.closest("[data-layer='true']").dataset.name,
                                                'price': e.target.closest("[data-layer='true']").dataset.price,
                                                'brand': e.target.closest("[data-layer='true']").dataset.brand,
                                                'category': e.target.closest("[data-layer='true']").dataset.category,
                                                'variant': e.target.closest("[data-layer='true']").dataset.variant,
                                                'quantity': 1,
                                                'dimension7': e.target.closest(".checkout-order__item").dataset.dimension,
                                                'dimension8': e.target.dataset.hasOwnProperty("paperType") ? e.target.dataset.paperType : "",
                                            }]
                                        }
                                    }
                                });

                            }
                            if (e.target.dataset.basket == "remove-all") {
                                dataLayer.push({ ecommerce: null });
                                dataLayer.push({
                                    'event':'removeFromCart',
                                    'ecommerce': {
                                        'remove': {
                                            'products': [{
                                                'id': e.target.closest("[data-layer='true']").dataset.id,
                                                'name': e.target.closest("[data-layer='true']").dataset.name,
                                                'price': e.target.closest("[data-layer='true']").dataset.price,
                                                'brand': e.target.closest("[data-layer='true']").dataset.brand,
                                                'category': e.target.closest("[data-layer='true']").dataset.category,
                                                'variant': e.target.closest("[data-layer='true']").dataset.variant,
                                                'quantity': parseInt(e.target.dataset.quantity),
                                                'dimension7': e.target.closest(".checkout-order__item").dataset.dimension,
                                                'dimension8': e.target.dataset.hasOwnProperty("paperType") ? e.target.dataset.paperType : "",
                                            }]
                                        }
                                    }
                                });

                            }
                        },
                        error => {
                            basketSending = false;
                            self.parentElement.classList.remove("sending");
                            showError();
                            console.error(error);
                        }
                    );
                }
                if (e.target.dataset.order == "add") {
                    e.preventDefault();
                    e.stopPropagation();
                    if (basketSending || e.target.dataset.priceId == "") {
                        return;
                    }
                    basketSidebarItems = document.querySelector(".basket-widget .checkout__basket-wrap .simplebar-content");
                    basketSending = true;
                    basketAddWrap.classList.remove("loaded");
                    basketAddWrap.classList.remove("success");
                    basketAddWrap.classList.remove("inBasket");
                    basketAddWrap.classList.add("open");
                    let priceId = e.target.dataset.priceId;
                    let numberId = e.target.dataset.numberId;
                    let url = "/koszyk/dodaj/";
                    url += priceId;
                    if (numberId != "") {
                        url += "/" + numberId;
                    }
                    add = new XHRWrap({
                        url: url
                    });
                    add.send().then(
                        result => {

                            if (result.data.hasOwnProperty("quantity")) {
                                basketCount.classList.remove("d-none");
                                basketCount.innerText = result.data.quantity
                            }
                            if (result.html.hasOwnProperty("basket") && checkoutBasketWrap.length > 0) {
                                if (result.result) {
                                    for (var i = 0; i < checkoutBasketWrap.length; i++) {
                                        if (checkoutBasketWrap[i].classList.contains("checkout__basket-wrap--sidebar")) {
                                            continue;
                                        }
                                        checkoutBasketWrap[i].innerHTML = result.html.basket;
                                    }
                                    basketSending = false;
                                }
                            }
                            if (result.html.hasOwnProperty("basketList") && basketSidebarItems) {
                                basketSidebarItems.innerHTML = result.html.basketList;
                                checkoutOrder.classList.remove("checkout-order--empty");
                                basketWidget.classList.remove("basket-widget--empty");
                                basketSummaryVal.innerText = result.html.amountTotal;
                                widgetDiscount.innerHTML = result.html.discount;
                            }
                            if (result.html.hasOwnProperty("shippingCost") && basketShippingVal) {
                                basketShippingVal.innerText = result.html.shippingCost;
                            }
                            if (result.html.hasOwnProperty("basketAdd") && result.html.basketAdd != null) {
                                basketAddWrap.classList.add("loaded");
                                basketAddWrap.classList.add("success");
                                if (result.data.hasOwnProperty("result") && !result.data.result) {
                                    basketAddWrap.classList.add("inBasket");
                                }
                                basketAddContent.innerHTML = result.html.basketAdd;
                                if (basketAddContent.querySelector("script")) {
                                    eval(basketAddContent.querySelector("script").innerHTML);
                                }
                            }
                        },
                        error => {
                            showError();
                            self.parentElement.classList.remove("sending");
                            console.error(error);
                            basketSending = false;
                        }
                    )
                }
            });
        }
    }

    if (checkoutForm) {
        const checkoutSubmit = checkoutForm.querySelector(".checkout__form-submit");
        checkoutForm.addEventListener("submit", function(e){
            e.preventDefault();
            const self = this;
            if (!formSending && !basketSending) {

                formSending = true;
                self.parentElement.classList.add("sending");
                checkSend.value = 1;
                send = new XHRWrap({
                    url: "/zamowienie",
                    form: checkoutForm,
                });
                send.send().then(
                    result => {
                        checkoutLeft.innerHTML = send.html.order;
                        checkoutTop.innerHTML = send.html.orderTop;
                        formSending = false;
                        if (!result.result) {
                            window.scrollTo(0, 0);
                            self.parentElement.classList.remove("sending");
                        }
                        else {
                            if (typeof(orderProductsArray) != "undefined" && orderProductsArray.length > 0 && typeof(dataLayer) != "undefined") {
                                dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                                dataLayer.push({
                                    'event': 'checkout',
                                    'ecommerce': {
                                        'checkout': {
                                            'actionField': {
                                                'step': 2,
                                                'option': checkoutForm.querySelector("input[name*='platnosc']:checked") ? checkoutForm.querySelector("input[name*='platnosc']:checked").nextElementSibling.innerText.trim() : "",
                                            },
                                            'products': orderProductsArray,
                                        }
                                    }
                                });
                            }
                            window.location.href = document.location.origin + "/podsumowanie-zamowienia";
                        }
                    },
                    error => {
                        self.parentElement.classList.remove("sending");
                        showError()
                        formSending = false;
                        console.error(error);
                    }
                )
            }
        });
        checkoutForm.addEventListener("change", function(e){
            const self = this;
            let discountVal = this.querySelector("#kod_rabatowy").value;
            let subscribeNrVal = this.querySelector("#numer_prenumeraty").value;

            if (e.target.id === "check-all") {
                const allCheckbox = this.querySelectorAll("[data-check-all]");
                for (var i = 0; i < allCheckbox.length; i++) {
                    allCheckbox[i].checked = e.target.checked;
                }
            }

            if (e.target.dataset.hasOwnProperty("checkAll")) {
                const allCheckboxs = this.querySelectorAll("[data-check-all]");
                const allCheckbox = this.querySelector("#check-all");
                let allCheck = true;
                for (var i = 0; i < allCheckboxs.length; i++) {
                    if (!allCheckboxs[i].checked) {
                        allCheck = false;
                    }
                }
                allCheckbox.checked = allCheck;
            }

            let commentWrap = this.querySelector("label[for='in-uwagi']");
            if (e.target.id === "order-comment") {
                if (e.target.checked) {
                    commentWrap.classList.remove("d-none");
                }
                else {
                    commentWrap.classList.add("d-none");
                }
            }

            const invoiceWrap = this.querySelector(".checkout__radio-dropdown--invoice");
            if (e.target.name === "platnik_adres_wysylkowy") {
                if (e.target.value === "inny") {
                    invoiceWrap.classList.add("open");
                }
                else {
                    invoiceWrap.classList.remove("open");
                }
            }

            const inputsInvoice = this.querySelectorAll("[data-invoice]");
            if (e.target.name === "platnik_rodzaj") {
                for (var i = 0; i < inputsInvoice.length; i++) {
                    const input = inputsInvoice[i];
                    if (e.target.value === "firma") {
                        if (input.dataset.invoice === "firm") {
                            input.setAttribute("data-required", "true");
                            input.parentElement.classList.remove("d-none");
                        }
                        else {
                            input.removeAttribute("data-required");
                            input.parentElement.classList.add("d-none");
                        }
                    }
                    else {
                        if (input.dataset.invoice === "private") {
                            input.setAttribute("data-required", "true");
                            input.parentElement.classList.remove("d-none");
                        }
                        else {
                            input.removeAttribute("data-required");
                            input.parentElement.classList.add("d-none");
                        }
                    }
                }

            }

            const inpostRadio = this.querySelector("#sposob_dostawy_761");
            const inpostWrap = document.querySelector(".checkout__inpost");
            if (inpostWrap && inpostRadio) {
                if (inpostRadio.checked) {
                    inpostWrap.classList.add("open");
                }
                else {
                    inpostWrap.classList.remove("open");
                }
            }
            if (e.target.classList.contains("checkout__form-radio--delivery")) {
                self.parentElement.classList.add("sending");
                checkCode = new XHRWrap({
                    url: "/order/delivery-method-change",
                    form: checkoutForm
                });
                checkCode.send().then(
                    result => {
                        if (result.html.hasOwnProperty("basket")) {
                            if (result.result) {
                                for (var i = 0; i < checkoutBasketWrap.length; i++) {
                                    if (checkoutBasketWrap[i].classList.contains("checkout__basket-wrap--sidebar")) {
                                        continue;
                                    }
                                    checkoutBasketWrap[i].innerHTML = result.html.basket;
                                }
                            }
                            else if (!result.result && result.html.hasOwnProperty("recommendation")) {
                                for (var i = 0; i < checkoutBasketWrap.length; i++) {
                                    if (checkoutBasketWrap[i].classList.contains("checkout__basket-wrap--sidebar")) {
                                        continue;
                                    }
                                    checkoutBasketWrap[i].innerHTML = result.html.recommendation;
                                }
                                if (checkoutBasketSidebar) {
                                    let checkoutBasketOrder = checkoutBasketSidebar.querySelector(".checkout-order");
                                    checkoutBasketOrder.classList.add("checkout-order--empty");
                                }
                                if (basketWidget) {
                                    basketWidget.classList.add("basket-widget--empty");
                                }
                            }
                        }
                        if (result.html.hasOwnProperty("basketList") && basketSidebarItems) {
                            basketSidebarItems.innerHTML = result.html.basketList;
                        }
                        if (result.html.hasOwnProperty("orderTop") && checkoutTop) {
                            checkoutTop.innerHTML = result.html.orderTop;
                        }
                        if (result.data.hasOwnProperty("quantity") && basketCount) {
                            basketCount.innerText = result.data.quantity;
                        }
                        if (result.html.hasOwnProperty("order") && checkoutLeft) {
                            checkoutLeft.innerHTML = result.html.order;
                        }
                        if (result.html.hasOwnProperty("amountTotal") && basketSummaryVal) {
                            basketSummaryVal.innerText = result.html.amountTotal;
                            checkBotPrice.innerText = result.html.amountTotal;
                        }
                        if (result.html.hasOwnProperty("shippingCost") && basketShippingVal) {
                            basketShippingVal.innerText = result.html.shippingCost;
                        }
                        if (result.html.hasOwnProperty("discount") && widgetDiscount) {
                            widgetDiscount.innerHTML = result.html.discount;
                        }
                        // INPOST
                        const inpostRadio = document.querySelector("#sposob_dostawy_761");
                        const inpostWrap = document.querySelector(".checkout__inpost");
                        if (inpostWrap && inpostRadio) {
                            if (inpostRadio.checked) {
                                inpostWrap.classList.add("open");
                            }
                            else {
                                inpostWrap.classList.remove("open");
                            }
                        }
                        // INPOST END
                        checkingCode = false;
                        self.parentElement.classList.remove("sending");
                    },
                    error => {
                        showError();
                        console.error(error);
                        checkingCode = false;
                        self.parentElement.classList.remove("sending");
                    }
                )
            }

            if (!checkingCode && e.target.name == "rabat" && e.target.value == "over_50" && (discountVal != "" || subscribeNrVal != "")) {
                checkDiscount = true;
                checkingCode = true;
                self.parentElement.classList.add("sending");
                checkCode = new XHRWrap({
                    url: "/rabat",
                    form: checkoutForm
                });
                checkCode.send().then(
                    result => {
                        if (result.html.hasOwnProperty("basket")) {
                            if (result.result) {
                                for (var i = 0; i < checkoutBasketWrap.length; i++) {
                                    if (checkoutBasketWrap[i].classList.contains("checkout__basket-wrap--sidebar")) {
                                        continue;
                                    }
                                    checkoutBasketWrap[i].innerHTML = result.html.basket;
                                }
                            }
                            else if (!result.result && result.html.hasOwnProperty("recommendation")) {
                                for (var i = 0; i < checkoutBasketWrap.length; i++) {
                                    if (checkoutBasketWrap[i].classList.contains("checkout__basket-wrap--sidebar")) {
                                        continue;
                                    }
                                    checkoutBasketWrap[i].innerHTML = result.html.recommendation;
                                }
                                if (checkoutBasketSidebar) {
                                    let checkoutBasketOrder = checkoutBasketSidebar.querySelector(".checkout-order");
                                    checkoutBasketOrder.classList.add("checkout-order--empty");
                                }
                                if (basketWidget) {
                                    basketWidget.classList.add("basket-widget--empty");
                                }
                            }
                        }
                        if (result.html.hasOwnProperty("basketList") && basketSidebarItems) {
                            basketSidebarItems.innerHTML = result.html.basketList;
                        }
                        if (result.html.hasOwnProperty("orderTop") && checkoutTop) {
                            checkoutTop.innerHTML = result.html.orderTop;
                        }
                        if (result.data.hasOwnProperty("quantity") && basketCount) {
                            basketCount.innerText = result.data.quantity;
                        }
                        if (result.html.hasOwnProperty("order") && checkoutLeft) {
                            checkoutLeft.innerHTML = result.html.order;
                        }
                        if (result.html.hasOwnProperty("amountTotal") && basketSummaryVal) {
                            basketSummaryVal.innerText = result.html.amountTotal;
                            checkBotPrice.innerText = result.html.amountTotal;
                        }
                        if (result.html.hasOwnProperty("shippingCost") && basketShippingVal) {
                            basketShippingVal.innerText = result.html.shippingCost;
                        }
                        if (result.html.hasOwnProperty("discount") && widgetDiscount) {
                            widgetDiscount.innerHTML = result.html.discount;
                        }
                        // INPOST
                        const inpostRadio = document.querySelector("#sposob_dostawy_761");
                        const inpostWrap = document.querySelector(".checkout__inpost");
                        if (inpostWrap && inpostRadio) {
                            if (inpostRadio.checked) {
                                inpostWrap.classList.add("open");
                            }
                            else {
                                inpostWrap.classList.remove("open");
                            }
                        }
                        // INPOST END
                        checkingCode = false;
                        self.parentElement.classList.remove("sending");
                    },
                    error => {
                        showError();
                        console.error(error);
                        checkingCode = false;
                        self.parentElement.classList.remove("sending");
                    }
                )
            }
        });
    }
    if (checkoutLeft) {
        if (document.URL.indexOf("guest=1") != -1) {
            docCookies.setCookie("guest", 1, 1, "/");
        }
        checkoutLeft.addEventListener("click", function(e){
            const self = this;

            if (e.target.classList.contains("checkout__delivery-description")) {
                e.target.parentElement.classList.toggle("open");
            }

            const inpostWrap = document.querySelector(".checkout__inpost");
            if (e.target.classList.contains("checkout__inpost-btn")) {
                inpostWrap.classList.add("widget-open");
            }
            if (e.target.classList.contains("checkout__inpost-widget-close") || e.target.classList.contains("checkout__inpost-widget")) {
                inpostWrap.classList.remove("widget-open");
            }
            if ((e.target.dataset.checkout == "banks") && checkoutBanks) {
                e.stopPropagation();
                e.preventDefault();
                checkoutBanks.classList.add("open");
            }
            if (e.target.dataset.checkout == "submit") {
                e.preventDefault();
                if (!formSending && !basketSending) {
                    checkSend.value = 1;
                    formSending = true;
                    send = new XHRWrap({
                        url: "/zamowienie",
                        form: checkoutForm,
                    });
                    send.form.parentElement.classList.add("sending");
                    send.send().then(
                        result => {
                            checkoutLeft.innerHTML = send.html.order;
                            checkoutTop.innerHTML = send.html.orderTop;
                            formSending = false;
                            if (!result.result) {
                                window.scrollTo(0, 0);
                                send.form.parentElement.classList.remove("sending");
                            }
                            else {
                                if (typeof(orderProductsArray) != "undefined" && orderProductsArray.length > 0 && typeof(dataLayer) != "undefined") {
                                    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                                    dataLayer.push({
                                        'event': 'checkout',
                                        'ecommerce': {
                                            'checkout': {
                                                'actionField': {
                                                    'step': 2,
                                                    'option': checkoutForm.querySelector("input[name*='platnosc']:checked") ? checkoutForm.querySelector("input[name*='platnosc']:checked").nextElementSibling.innerText.trim() : "",
                                                },
                                                'products': orderProductsArray,
                                            }
                                        }
                                    });
                                }
                                window.location.href = document.location.origin + "/podsumowanie-zamowienia";
                            }
                        },
                        error => {
                            send.form.parentElement.classList.remove("sending");
                            showError();
                            formSending = false;
                            console.error(error);
                        }
                    )
                }
            }
            if (e.target.dataset.checkout == "check-code") {
                checkDiscount = false;
                if (!checkingCode) {
                    checkingCode = true;
                    e.target.dataset.state = "check";
                    e.target.dataset.value = document.getElementById(e.target.dataset.id).value;
                    e.target.querySelector("span").innerText = "Sprawdzanie";
                    checkCode = new XHRWrap({
                        url: "/rabat",
                        form: checkoutForm
                    });
                    checkCode.form.parentElement.classList.add("sending");
                    checkCode.send().then(
                        result => {
                            if (result.data.codeResult) {
                                e.target.dataset.state = "succes";
                                e.target.querySelector("span").innerText = result.data.codeMessage;
                            }
                            else {
                                e.target.dataset.state = "error";
                                e.target.querySelector("span").innerText = result.data.codeMessage;
                            }
                            if (result.html.hasOwnProperty("basket")) {
                                if (result.result) {
                                    for (var i = 0; i < checkoutBasketWrap.length; i++) {
                                        if (checkoutBasketWrap[i].classList.contains("checkout__basket-wrap--sidebar")) {
                                            continue;
                                        }
                                        checkoutBasketWrap[i].innerHTML = result.html.basket;
                                    }
                                }
                                else if (!result.result && result.html.hasOwnProperty("recommendation")) {
                                    for (var i = 0; i < checkoutBasketWrap.length; i++) {
                                        if (checkoutBasketWrap[i].classList.contains("checkout__basket-wrap--sidebar")) {
                                            continue;
                                        }
                                        checkoutBasketWrap[i].innerHTML = result.html.recommendation;
                                    }
                                    if (checkoutBasketSidebar) {
                                        let checkoutBasketOrder = checkoutBasketSidebar.querySelector(".checkout-order");
                                        checkoutBasketOrder.classList.add("checkout-order--empty");
                                    }
                                    if (basketWidget) {
                                        basketWidget.classList.add("basket-widget--empty");
                                    }
                                }
                            }
                            if (result.html.hasOwnProperty("basketList") && basketSidebarItems) {
                                basketSidebarItems.innerHTML = result.html.basketList;
                            }
                            if (result.html.hasOwnProperty("orderTop") && checkoutTop) {
                                checkoutTop.innerHTML = result.html.orderTop;
                            }
                            if (result.data.hasOwnProperty("quantity") && basketCount) {
                                basketCount.innerText = result.data.quantity;
                            }
                            if (result.html.hasOwnProperty("order") && checkoutLeft) {
                                checkoutLeft.innerHTML = result.html.order;
                            }
                            if (result.html.hasOwnProperty("amountTotal") && basketSummaryVal) {
                                basketSummaryVal.innerText = result.html.amountTotal;
                                checkBotPrice.innerText = result.html.amountTotal;
                            }
                            if (result.html.hasOwnProperty("shippingCost") && basketShippingVal) {
                                basketShippingVal.innerText = result.html.shippingCost;
                            }
                            if (result.html.hasOwnProperty("discount") && widgetDiscount) {
                                widgetDiscount.innerHTML = result.html.discount;
                            }
                            checkingCode = false;
                            checkCode.form.parentElement.classList.remove("sending");

                        },
                        error => {
                            showError();
                            console.error(error);
                            checkingCode = false;
                            checkCode.form.parentElement.classList.remove("sending");

                        }
                    )
                }
            }
        });
        if (checkoutBanks) {
            checkoutBanks.addEventListener("click", function(e){
                if (e.target.classList.contains("checkout__banks") || e.target.classList.contains("checkout__banks-close")) {
                    this.classList.remove("open");
                }
            });
        }
    }
    if (orderAddBtns.length > 0) {
        for (var i = 0; i < orderAddBtns.length; i++) {
            orderAddBtns[i].addEventListener("click", function(e){
                e.preventDefault();
                e.stopPropagation();
                const self = this;
                if (basketSending || e.target.dataset.priceId == "") {
                    return;
                }
                basketSidebarItems = document.querySelector(".basket-widget .checkout__basket-wrap .simplebar-content");
                basketSending = true;
                basketAddWrap.classList.remove("loaded");
                basketAddWrap.classList.remove("success");
                basketAddWrap.classList.remove("inBasket");
                basketAddWrap.classList.add("open");
                let priceId = e.target.dataset.priceId;
                let numberId = e.target.dataset.numberId;
                let subscriptionLink = false;
                if (e.target.dataset.hasOwnProperty("subscriptionLink")) {
                    subscriptionLink = e.target.dataset.subscriptionLink;
                }
                let url = "/koszyk/dodaj/";
                url += priceId;
                if (numberId != "") {
                    url += "/" + numberId;
                }
                if (subscriptionLink) {
                    url = subscriptionLink;
                }
                add = new XHRWrap({
                    url: url
                });
                add.send().then(
                    result => {

                        if (result.data.hasOwnProperty("quantity")) {
                            basketCount.classList.remove("d-none");
                            basketCount.innerText = result.data.quantity
                        }
                        if (result.html.hasOwnProperty("basket") && checkoutBasketWrap.length > 0) {
                            if (result.result) {
                                for (var i = 0; i < checkoutBasketWrap.length; i++) {
                                    if (checkoutBasketWrap[i].classList.contains("checkout__basket-wrap--sidebar")) {
                                        continue;
                                    }
                                    checkoutBasketWrap[i].innerHTML = result.html.basket;
                                }
                                basketSending = false;
                            }
                        }
                        if (result.html.hasOwnProperty("basketList") && basketSidebarItems) {
                            basketSidebarItems.innerHTML = result.html.basketList;
                            checkoutOrder.classList.remove("checkout-order--empty");
                            basketWidget.classList.remove("basket-widget--empty");
                            basketSummaryVal.innerText = result.html.amountTotal;
                            widgetDiscount.innerHTML = result.html.discount;
                        }
                        if (result.html.hasOwnProperty("shippingCost") && basketShippingVal) {
                            basketShippingVal.innerText = result.html.shippingCost;
                        }
                        if (result.html.hasOwnProperty("basketAdd") && result.html.basketAdd != null) {
                            basketAddWrap.classList.add("loaded");
                            basketAddWrap.classList.add("success");
                            if (result.data.hasOwnProperty("result") && !result.data.result) {
                                basketAddWrap.classList.add("inBasket");
                            }
                            basketAddContent.innerHTML = result.html.basketAdd;
                            if (basketAddContent.querySelector("script")) {
                                eval(basketAddContent.querySelector("script").innerHTML);
                            }
                        }
                        // INPOST
                        const inpostRadio = document.querySelector("#sposob_dostawy_761");
                        const inpostWrap = document.querySelector(".checkout__inpost");
                        if (inpostWrap && inpostRadio) {
                            if (inpostRadio.checked) {
                                inpostWrap.classList.add("open");
                            }
                            else {
                                inpostWrap.classList.remove("open");
                            }
                        }
                        // INPOST END
                        if (result.data.result) {
                            if (typeof(fbq) != "undefined") {
                                fbq('track', 'AddToCart', {
                                    content_name: self.dataset.pixelName ? self.dataset.pixelName : "",
                                    content_category: self.dataset.pixelCategory ? self.dataset.pixelCategory : "",
                                    content_ids: [
                                        self.dataset.pixelId ? self.dataset.pixelId : "",
                                    ],
                                    content_type: 'product',
                                    value: self.dataset.pixelValue ? self.dataset.pixelValue : "",
                                    currency: self.dataset.pixelCurrency ? self.dataset.pixelCurrency : ""
                                });
                            }
                            if (typeof(dataLayer) != "undefined") {
                                dataLayer.push({ ecommerce: null });
                                dataLayer.push({
                                   'event':'addToCart',
                                   'ecommerce': {
                                       'add': {
                                           'products': [{
                                                'id': self.dataset.pixelId,
                                                'name': self.closest("[data-layer='true']").dataset.name,
                                                'price': self.dataset.pixelValue,
                                                'brand': self.closest("[data-layer='true']").dataset.brand,
                                                'category': self.closest("[data-layer='true']").dataset.category,
                                                'variant': self.closest("[data-layer='true']").dataset.variant,
                                                'quantity': 1,
                                                'dimension7': self.closest("[data-layer='true']").dataset.dimension,
                                                'dimension8': self.dataset.hasOwnProperty("paperType") ? self.dataset.paperType : "",
                                            }]
                                        }
                                    }
                                });
                            }
                        }
                    },
                    error => {
                        showError();
                        console.error(error);
                        basketSending = false;
                    }
                )
            });
        }
    }
    /* ajax end */



    // Buy options

    const buyOptionsBtn = document.querySelectorAll(".js-buy-option");
    const buyOptionsClose = document.getElementsByClassName("buy-options__close");
    if (buyOptionsBtn.length > 0) {
        for (var i = 0; i < buyOptionsBtn.length; i++) {
            buyOptionsBtn[i].addEventListener("click", function(e){
                if (!e.target.classList.contains("buy-options__item--link")) {
                    e.stopPropagation();
                    e.preventDefault();
                }
                let options;
                for (var j = 0; j < buyOptionsBtn.length; j++) {
                    options = buyOptionsBtn[j].querySelector(".buy-options");
                    if (options) {
                        options.classList.remove("open");
                        options = false;
                    }
                }
                options = this.querySelector(".buy-options");
                if (options) {
                    options.classList.add("open");
                }
            });
            buyOptionsClose[i].addEventListener("click", function(e){
                e.stopPropagation();
                e.preventDefault();
                this.parentElement.classList.remove("open");
            });
        }
        document.addEventListener("click", function(e){
            if (e.target.className.indexOf("buy-options") < 0 && !e.target.classList.contains("js-buy-option")) {
                for (var i = 0; i < buyOptionsBtn.length; i++) {
                    buyOptionsBtn[i].querySelector(".buy-options").classList.remove("open");
                }
            }
        });
    }

    if (basketAddClose.length > 0) {
        for (var i = 0; i < basketAddClose.length; i++) {
            basketAddClose[i].addEventListener("click", function(e){
                e.preventDefault();
                e.stopPropagation();
                basketAddWrap.classList.remove("open");
                basketError.classList.remove("open");
                let timer = setTimeout(function(){
                    basketAddWrap.classList.remove("success");
                    basketAddWrap.classList.remove("inBasket");
                    basketAddWrap.classList.remove("loaded");
                }, 300);
            });
        }
    }




    // Send order
    const messageSuccesText = document.querySelector(".checkout__form-message.succes p");
    const checkoutSummary = document.querySelector(".checkout-summary");
    const kukPixel = document.querySelector("input[name='kuk_pixel']");
    const zgodaPixel = document.querySelector("input[name='zgoda_pixel']");
    let checkoutSending = false;
    if (sendOrder) {
        sendOrder.addEventListener("click", function(e){
            e.preventDefault();
            e.stopPropagation();
            if (typeof(pixelJSON) != "undefined" && typeof(fbq) != "undefined") {
                fbq('track', 'InitiateCheckout', pixelJSON);
            }
            if (checkoutSending) {
                return;
            }
            checkoutSending = true;
            checkoutSummary.classList.add("sending");
            window.scrollTo(0, 0);
            send = new XHRWrap({
                url: "/podsumowanie-zamowienia",
            });
            send.send().then(
                result => {
                    if (result.result) {
                        if (typeof(orderProductsArray) != "undefined" && orderProductsArray.length > 0 && typeof(dataLayer) != "undefined") {
                            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                            dataLayer.push({
                                'event': 'checkout',
                                'ecommerce': {
                                    'checkout': {
                                        'actionField': {
                                            'step': 4,
                                            'option': checkoutForm && checkoutForm.querySelector("input[name*='platnosc']:checked") ? checkoutForm.querySelector("input[name*='platnosc']:checked").nextElementSibling.innerText.trim() : document.querySelector(".summary__item-data--payment").innerText.trim(),
                                        },
                                        'products': orderProductsArray,
                                    }
                                }
                            });
                        }
                        checkoutSummary.classList.remove("sending");
                        checkoutSummary.classList.remove("check");
                        checkoutSummary.classList.add("succes");
                        checkoutSummary.classList.add("sended");
                        messageSuccesText.innerHTML = result.message;
                        const eservice = document.getElementById("eservice");
                        if (kukPixel && kukPixel.value == 1 && typeof(fbq) != "undefined") {
                            fbq('track', 'Lead');
                        }
                        if (zgodaPixel && zgodaPixel.value == 1 && typeof(fbq) != "undefined") {
                            fbq('track', 'CompleteRegistration');
                        }
                        checkoutSending = false;
                        document.querySelector(".checkout__step.active").classList.remove("active");
                        document.querySelector(".checkout__step[data-step='3']").classList.add("active");

                        if (result.data.redirectUrl) {
                            let timer = setTimeout(function(){
                                document.location = result.data.redirectUrl;
                            }, 3000)
                        }
                        if (eservice) {
                            if(result.data.token.valid === true) {
                                const eserviceTotal = document.querySelector('#eservice #total');
                                const eserviceToken = document.querySelector('#eservice #token');
                                eserviceTotal.value = result.data.total;
                                eserviceToken.value = result.data.token.message;
                            }
                            let timer = setTimeout(function(){
                                eservice.submit();
                            }, 3000)
                        }
                    }
                    else {
                        window.location.href = document.location.origin + "/zamowienie";
                    }
                },
                error => {
                    checkoutSummary.classList.remove("sending");
                    showError();
                    checkoutSending = false;
                    console.error(error);
                },
            )
        });
    }

    // Regulations text

    const regulationsText = document.querySelector(".js-hidden-text");
    if (regulationsText) {
        const regulationsBtn = regulationsText.querySelector(".read-all");
        regulationsBtn.addEventListener("click", function(e){
            e.preventDefault();
            e.stopPropagation();
            regulationsText.classList.toggle("open");
        });
    }

    const selectGift = document.querySelector(".select-gift");
    if (selectGift) {
        const checkoutSticky = document.getElementsByClassName("checkout__basket-sticky")[0];
        checkoutSticky.classList.add("p-static");
        document.addEventListener("click", function(e){
            if (e.target.className.indexOf("select-gift") < 0) {
                selectGift.classList.remove("open");
            }
        })
    }

    document.addEventListener("click", function(e){
        if (e.target.nodeName.toLowerCase() === "a" && e.target.dataset.hasOwnProperty("layer") && e.target.dataset.layer == "link") {
            dataLayer.push({
                'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list': e.target.closest("[data-layer='true']").dataset.listName},
                        'products': [{
                            'id': e.target.closest("[data-layer='true']").dataset.id,
                            'name': e.target.closest("[data-layer='true']").dataset.name,
                            'price': e.target.closest("[data-layer='true']").dataset.price,
                            'brand': e.target.closest("[data-layer='true']").dataset.brand,
                            'category': e.target.closest("[data-layer='true']").dataset.category,
                            'variant': e.target.closest("[data-layer='true']").dataset.variant,
                            'position': e.target.closest("[data-layer='true']").dataset.position,
                            'dimension7': e.target.closest("[data-layer='true']").dataset.dimension,
                        }]
                    }
                },
            });

        }
    });
    const inpostRadio = document.querySelector("#sposob_dostawy_761");
    const inpostWrap = document.querySelector(".checkout__inpost");
    if (inpostWrap && inpostRadio) {
        if (inpostRadio.checked) {
            inpostWrap.classList.add("open");
        }
        else {
            inpostWrap.classList.remove("open");
        }
    }
};

export default order;
